import React, { useEffect, useState } from 'react';
import '../App.css';      
import { UilUsersAlt, UilFileUpload, UilFileAlt, UilDownloadAlt, UilSignout } from '@iconscout/react-unicons';
import getEndpoint from './Endpoint'; // Import the getEndpoint function
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse'; // Import PapaParse library
// UploadCSV component as a functional component
const UploadCSV = () => {
  // State to manage the selected file and its name
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  //const navigate = useNavigate();

  const [employeeList, setEmployeeList] = useState([]);

  const username = localStorage.getItem('username');
  const [errorMessage, setErrorMessage] = useState(null);

const fetchEmployeeData = async () => {
  try {
    const endpoint = getEndpoint('employeeApiEndpoint');
    const username = localStorage.getItem('username');
    const response = await axios.get(endpoint, {
      headers: {
        'username': username,
        'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
        'Content-Type': 'application/json',
      },
    });
    console.log('Employee List:', response.data);
    setEmployeeList(response.data);

     // Add the logging statement here
     console.log('Employee List:', response.data);
  } catch (error) {
    console.error('Error fetching employee data:', error.message);
  }
};

  useEffect(() => {
    fetchEmployeeData();
  }, []); 
  
  const notifySuccesful = () => {toast.success('File Upload Successful!', {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    // transition: Bounce,
    });
  }

  const notifyFailed = () => {toast.error('File Upload Failed!', {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    // transition: Bounce,
    });
  }
  const notifyNofile = () => {toast.warn('Please choose a file!', {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    // transition: Bounce,
    });
  }
  // Function to update the selected file and its name
  const updateFileName = () => {
    const fileInput = document.getElementById('fileInput');
    const fileNameDisplay = document.getElementById('fileName');
    const fileName = fileInput.files[0] ? fileInput.files[0].name : 'No file chosen';
    fileNameDisplay.textContent = fileName;
  };
  // Event listener for file input change
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    updateFileName();
  };
// Event listener for form submission
const handleFormSubmit = async (event) => {
  event.preventDefault();
  // Check if a file is selected
  if (!selectedFile) {
      console.log('No file chosen');
      notifyNofile();
      return;
  }
  try {
    // Read the contents of the selected file
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
        try {
            const fileContent = e.target.result;
            // Parse the CSV file content
            const parsedCSV = Papa.parse(fileContent, { header: true });
            // Validate each row of the CSV starting from the first row with data
            const validationErrors = [];
            const updatedEmployeeList = [...employeeList]; // Create a copy of the current state
            parsedCSV.data.forEach((row, index) => {
                // Skip empty rows
                if (Object.values(row).every(value => value === "")) {
                    return;
                }

              
               // Log the state before updating
            console.log('EmployeeList before update:', employeeList);
            // Set the state with the updated array
            setEmployeeList(updatedEmployeeList);
            // Log the state after updating
            console.log('EmployeeList after update:', updatedEmployeeList);

                // Validate FirstName and LastName
                if (!row.FirstName || !/^[A-Za-z]+$/.test(row.FirstName.trim())) {
                  validationErrors.push(`Row ${index + 1}: Missing or Invalid First Name: ${row.LastName}. First Name must contain only alphabetic characters.`);
                  setErrorMessage(`Row ${index + 1}: Missing or Invalid First Name: ${row.LastName}. First Name must contain only alphabetic characters.`);
                  setTimeout(() => {
                    setErrorMessage(' ');
                  }, 5000); // Timeout for 5 seconds
                return;
                }
                if (!row.LastName || !/^[A-Za-z]+$/.test(row.LastName.trim())) {
                    validationErrors.push(`Row ${index + 1}: Missing or Invalid Last Name: ${row.LastName}. Last Name must contain only alphabetic characters.`);
                    setErrorMessage(`Row ${index + 1}: Missing or Invalid Last Name: ${row.LastName}. Last Name must contain only alphabetic characters.`);
                    setTimeout(() => {
                      setErrorMessage(' ');
                    }, 5000); // Timeout for 5 seconds
                  return;
                }
                // Validate EmailId
                if (row.EmailId && !validateEmail(row.EmailId)) {
                  validationErrors.push(`Row ${index + 1}: Invalid EmailId format: ${row.EmailId}`);
                  setErrorMessage(`Row ${index + 1}: Invalid EmailId format: ${row.EmailId}`);
                  setTimeout(() => {
                    setErrorMessage(' ');
                  }, 5000); // Timeout for 5 seconds
                  return;
                }
                // Validate Mobile
                if (row.Mobile && !/^\d{10}$/.test(row.Mobile)) {
                  validationErrors.push(`Row ${index + 1}: Invalid Mobile (10 numerics only): ${row.Mobile}`);
                  setErrorMessage(`Row ${index + 1}: Invalid Mobile (10 numerics only): ${row.Mobile}`);
                  setTimeout(() => {
                    setErrorMessage(' ');
                  }, 5000); // Timeout for 5 seconds
                  return;
                } 
                // Validate Pin
                if (!row.Pin || !/^\d{6}$/.test(row.Pin)) {
                    validationErrors.push(`Row ${index + 1}: Invalid Pin format: ${row.Pin}`);
                }


                if (
                  !row.Amount ||
                  isNaN(row.Amount) ||
                  (!(parseFloat(row.Amount) >= 0 && /^\d{4}$/.test(row.EmployeeId)) && !(parseFloat(row.Amount) >= 0 || parseFloat(row.Amount) < 0 && /^\d{6}$/.test(row.EmployeeId)))
                ) {
                  validationErrors.push(`Row ${index + 1}: Invalid Authorized Amount: ${row.Amount}. Authorized Amount must be a numerical value.`);
                }
                if (!row.AmountPaid || isNaN(row.AmountPaid) || parseFloat(row.AmountPaid) < 0) {
                    validationErrors.push(`Row ${index + 1}: Invalid Amount Paid: ${row.AmountPaid}. Amount Paid must be a numerical value greater than or equal to 0.`);
                }
            });

            if (validationErrors.length > 0) {
                // Display validation errors
                validationErrors.forEach((error) => console.error(error));
                // Display error message on website
                const messageLabel = document.getElementById('messageLabel');
                messageLabel.textContent = 'Invalid file format. Please check the console for details.';
                messageLabel.style.color = 'red';
                notifyFailed();
                return;
            }

            // Proceed with file upload
            const formData = new FormData();
            formData.append('file', selectedFile);
            const username = localStorage.getItem('username');
            const headers = { 'username': username };
            try {
                const response = await fetch(`${getEndpoint('uploadcsvApiEndpoint')}`, {
                    method: 'POST',
                    headers: headers,
                    body: formData
                });
                if (response.ok) {
                    const messageLabel = document.getElementById('messageLabel');
                    messageLabel.textContent = 'File uploaded successfully!';
                    messageLabel.style.color = 'green';
                    notifySuccesful();
                    setTimeout(() => {
                        navigate('/upload-file-status');
                        //window.location.href = '/upload-file-status';
                    }, 4000);
                } else {
                    const messageLabel = document.getElementById('messageLabel');
                    messageLabel.textContent = 'File upload failed.';
                    messageLabel.style.color = 'red';
                    notifyFailed();
                }
            } catch (error) {
                const messageLabel = document.getElementById('messageLabel');
                messageLabel.textContent = 'File upload failed.';
                messageLabel.style.color = 'red';
                console.error('Error:', error);
                notifyFailed();
            }
        } catch (error) {
            const messageLabel = document.getElementById('messageLabel');
            messageLabel.textContent = 'Error parsing the CSV file.';
            messageLabel.style.color = 'red';
            console.error('Error:', error);
            notifyFailed();
        }
    };
    fileReader.readAsText(selectedFile);
} catch (error) {
    const messageLabel = document.getElementById('messageLabel');
    messageLabel.textContent = 'File upload failed.';
    messageLabel.style.color = 'red';
    console.error('Error:', error);
    notifyFailed();
}
};
  
  
  // Function to validate email format
  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  
  const [isCollapsed, setCollapsed] = useState(false);
  // Toggle the collapse state
  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };
  const [showSignoutPopup, setShowSignoutPopup] = useState(false);
    const showSignoutConfirmation = () => {
      setShowSignoutPopup(true);
    };
    const hideSignoutConfirmation = () => {
      setShowSignoutPopup(false);
    };
    const handleSignout = () => {
      localStorage.clear();
      // Add any necessary signout logic here
      // For now, let's simulate a redirect to the login page
      window.location.href = "/";
    };
    const handleSubContractorListClick = () =>{
      navigate('/subcontractor-list');
    };
  
    const handleUploadCSVClick = () =>{
      navigate('/upload-csv');
    };
  
    const handleFileUploadStatusClick = () =>{
      navigate('/upload-file-status');
    };
  
    const handleDownloadTemplateClick = () =>{
      navigate('/download-template');
    };
  return (
    <div className={`wrapper ${isCollapsed ? 'collapse' : ''}`}>
      <div className="top_navbar">
        <div className="hamburger" onClick={toggleCollapse}>
          <img src={require('./Images/menu.png')} alt="" className="nav-menu-icon" />
        </div>
        <div className="top_menu">
          <div className="logo">Bolt Kiosk Admin</div>
        </div>
      </div>
      <div className="sidebar">
        <ul>
        <li>
            <a onClick={handleSubContractorListClick} >
              <span className="icon"><UilUsersAlt/></span>
              <span className="title">Subcontractor list</span>
            </a>
          </li>
          <li>
            <a onClick={handleUploadCSVClick} className="active">
              <span className="icon"><UilFileUpload /></span>
              <span className="title">Upload CSV</span>
            </a>
          </li>
          <li>
            <a onClick={handleFileUploadStatusClick}>
              <span className="icon"><UilFileAlt /></span>
              <span className="title">Upload File Status</span>
            </a>
          </li>
          <li>
            <a onClick={handleDownloadTemplateClick}>
              <span className="icon"><UilDownloadAlt /></span>
              <span className="title">Download Template</span>
            </a>
          </li>
          <li>
            <a onClick={showSignoutConfirmation}>
              <span className="icon sign-out"><UilSignout /></span>
              <span className="title">Signout</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="main_container">
      <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                // transition: Bounce,
                />
                
        <div className="main-page-header">
          <h3 className="dashboard-mainpage-h1">Upload CSV</h3>
        </div>
        <div className="div-center">
          <div className="upload-csv div-center">
            <div className="center-container">
              <div className="upload-form">
                <h1>Upload CSV</h1>
                <p>Select a .csv file</p>
                <form id="fileUploadForm" encType="multipart/form-data" onSubmit={handleFormSubmit}>
                  <label htmlFor="fileInput" className="file-label">Choose file</label>
                  <input type="file" id="fileInput" name="fileInput" className="file-input" onChange={handleFileChange} accept=".csv" />
                  <span id="fileName">No file chosen</span>
                  <div className='div-center'>
                    <button className="upload-csv-btn" type="submit">Upload</button>
                  </div>
                  <label id="messageLabel">
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Signout Confirmation Popup */}
      {showSignoutPopup && (
        <div className="overlay">
          <div className="modal flex-form">
          <button className="close-button" onClick={hideSignoutConfirmation}>
              &times;
            </button>
            <div className='form-label'>
            <p className="popup-text">Are you sure you want to Signout?</p>
            </div>
            <div className="form-row">
              <button className="cancel-button" onClick={hideSignoutConfirmation}>
                Cancel
              </button>
              <button className="logout-button" onClick={handleSignout}>
                Signout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default UploadCSV;