// Pagination.js

import React from 'react';

const Pagination = ({ currentPage, totalPages, handlePrevClick, handleNextClick, handlePageClick }) => {
  // Function to render pagination buttons for large devices
  const renderLargeDevicePagination = () => {
    
    return (
      <div id="pagination-container" className="pagination-container">
        {/* Left End: Display current page of total pages */}
        <div className="pagination-start">
          <div className="pagination-current-page">
            Page {currentPage} of {totalPages}
          </div>
        </div>

        {/* Right End: Pagination buttons */}
        <div className="pagination-end">
          <button
            className="pagination-btn"
            onClick={handlePrevClick}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {totalPages <= 5 ? (
            Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (page) => (
                <button
                  key={page}
                  className={`pagination-btn pagination-page-number ${
                    currentPage === page ? "current-page" : ""
                  }`}
                  onClick={() => handlePageClick(page)}
                >
                  {page}
                </button>
              )
            )
          ) : (
            <>
              {currentPage <= 3 && (
                <>
                  {[1, 2, 3, 4, 5].map((page) => (
                    <button
                      key={page}
                      className={`pagination-btn pagination-page-number ${
                        currentPage === page ? "current-page" : ""
                      }`}
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </button>
                  ))}
                  <span className="pagination-btn">...</span>
                  <button
                    className={`pagination-btn pagination-page-number ${
                      currentPage === totalPages ? "current-page" : ""
                    }`}
                    onClick={() => handlePageClick(totalPages)}
                  >
                    {totalPages}
                  </button>
                </>
              )}
              {currentPage > 3 && currentPage < totalPages - 2 && (
                <>
                  <button
                    className={`pagination-btn pagination-page-number ${
                      currentPage === 1 ? "current-page" : ""
                    }`}
                    onClick={() => handlePageClick(1)}
                  >
                    1
                  </button>
                  <span className="pagination-btn">...</span>
                  {[currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2].map((page) => (
                    <button
                      key={page}
                      className={`pagination-btn pagination-page-number ${
                        currentPage === page ? "current-page" : ""
                      }`}
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </button>
                  ))}
                  <span className="pagination-btn">...</span>
                  <button
                    className={`pagination-btn pagination-page-number ${
                      currentPage === totalPages ? "current-page" : ""
                    }`}
                    onClick={() => handlePageClick(totalPages)}
                  >
                    {totalPages}
                  </button>
                </>
              )}
              {currentPage >= totalPages - 2 && (
                <>
                  <button
                    className={`pagination-btn pagination-page-number ${
                      currentPage === 1 ? "current-page" : ""
                    }`}
                    onClick={() => handlePageClick(1)}
                  >
                    1
                  </button>
                  <span className="pagination-btn">...</span>
                  {[totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages].map((page) => (
                    <button
                      key={page}
                      className={`pagination-btn pagination-page-number ${
                        currentPage === page ? "current-page" : ""
                      }`}
                      onClick={() => handlePageClick(page)}
                    >
                      {page}
                    </button>
                  ))}
                </>
              )}
            </>
          )}
          <button
            className="pagination-btn"
            onClick={handleNextClick}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  // Function to render pagination buttons for small devices
  const renderSmallDevicePagination = () => {
    return (
      <div id="pagination-container" className="pagination-container">
        {/* Only render Prev, Current page, and Next buttons for small devices */}
        <div className="pagination-end">
          <div className="pagination-current-page">
            Page {currentPage} of {totalPages}
          </div>
          <button
            className="pagination-btn"
            onClick={handlePrevClick}
            disabled={currentPage === 1}
          >
            Prev
          </button>
            <button className="pagination-btn pagination-page-number top cursor-text current-page">{currentPage}</button>
          <button
            className="pagination-btn"
            onClick={handleNextClick}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {/* Check device width and render appropriate pagination */}
      {window.innerWidth <= 767
        ? renderSmallDevicePagination()
        : renderLargeDevicePagination()}
    </React.Fragment>
  );
};

export default Pagination;
