import React from 'react';

const UploadStatusTable = ({ jsonData, currentPage, recordsPerPage, error }) => {
  // If there's an error or jsonData is empty, display an error message
  if (error || jsonData.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan="8" style={{ textAlign: "center" }}>
            {error ? <p>Error: {error}</p> : <h1 className='error-msg'>No recent file uploaded!</h1>}
          </td>
        </tr>
      </tbody>
    );
  }

  // Otherwise, render the table body with data
  const tableBody = [];

  const startIdx = (currentPage - 1) * recordsPerPage;
  const endIdx = startIdx + recordsPerPage;

  for (let i = startIdx; i < endIdx && i < jsonData.length; i++) {
    const item = jsonData[i];
    const statusClass = item.status?.includes("Completed") ? "completed" : "";
    const emailContent = item.emailId ? item.emailId : "Email is not provided";
    const mobileContent = item.mobile ? item.mobile : "Mobile number is not provided";

    tableBody.push(
      <tr key={item.id}>
        <td>{item.employeeId}</td>
        <td>{item.firstName}</td>
        <td>{item.lastName}</td>
        <td>{emailContent}</td>
        <td>{mobileContent}</td>
        <td>${item.amount}</td>
        <td>${item.amountPaid}</td>
        <td className={statusClass}>{item.status}</td>
      </tr>
    );
  }

  return <tbody>{tableBody}</tbody>;
};

export default UploadStatusTable;
