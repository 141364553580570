// DownloadTemplate.js
import React, { useState, useEffect } from 'react';
import { UilUsersAlt, UilFileUpload, UilFileAlt, UilDownloadAlt, UilSignout } from '@iconscout/react-unicons';
import { useNavigate } from 'react-router-dom';
import getEndpoint from './Endpoint';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DownloadTemplate = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [contractorsList, setContractorsList] = useState([]);
  const navigate = useNavigate();

  const notify = () => {toast.success('Download Successful!', {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    // transition: Bounce,
    });
  }


  useEffect(() => {
    // Fetch subcontractors list when the component mounts
    const fetchSubcontractorsList = async () => {
      try {
        const list = await getSubcontractorsList();
        setContractorsList(list);
      } catch (error) {
        console.error('Error fetching subcontractors list:', error);
      }
    };

    fetchSubcontractorsList();
  },[]);



  // const getEndpoint = (key) => {
  //   const _endpointhosturl = 'https://localhost:7037/api/';
  //   // const _endpointhosturl = 'http://api.boltkioskadmin.com/api/';
  //   const endpoints = {
  //     'employeeApiEndpoint': 'Employee',
  //     // 'loginApiEndpoint': 'Auth/login',
  //     'uploadcsvApiEndpoint': 'FileUpload/upload?overwrite=true',
  //     'uploadStatusEndpoint': 'FileUpload/GetUploadFileStatus',
  //   };

  //   return _endpointhosturl + (endpoints[key] || 'Key not found!');
  // };
  const getSubcontractorsList = async () => {
    try {
      const endpoint = getEndpoint('employeeApiEndpoint');
      console.log('Endpoint:', endpoint);
  
      // const username = sessionStorage.getItem('username');
      const username = localStorage.getItem('username');

      console.log('Username:', username);
  
      const headers = {
        'username': username,
        'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
      };
  
      const response = await fetch(endpoint, { headers });
      console.log('Response:', response);
  
      if (!response.ok) {
        throw new Error('Failed to fetch subcontractors list');
      }
  
      const jsonData = await response.json();
      console.log('jsonData:', jsonData);
  
      return Array.isArray(jsonData) ? jsonData : [];
    } catch (error) {
      console.error('Error fetching subcontractors list:', error);
      return [];
    }
  };
  
  
  // const getSubcontractorsList = async () => {
  //   const getEmployees = getEndpoint('employeeApiEndpoint');

  //   // Get the 'username' from sessionStorage
  //   const username = sessionStorage.getItem('username');

  //   // Create headers object with the 'username' value
  //   const headers = {
  //     'username': username,
  //     'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',

  //   };

  //   const response = await fetch(getEmployees, {
  //     headers: headers
  //   });

  //   const jsonData = await response.json();
  //   return jsonData;
  // };

  const createCsvFromData = (data, fileName) => {
    if (!data || data.length === 0) {
      console.error('Invalid data for CSV creation');
      return;
    }

    let csvContent;

    if (data.length > 1) {
      // Removing unwanted columns and changing Authorized amount to 0
      data.forEach(row => {
        delete row.userId;
        delete row.status;
        delete row.token;
        delete row.dateTime;
        // row.amount = '0';
        // row.amountPaid = '0';
      });

      // Extract header and rows from the data
     // const header = Object.keys(data[0]).join(',');
     const header = "EmployeeId,FirstName,LastName,EmailId,Mobile,Pin,Amount,AmountPaid";

      const rows = data.map(row => Object.values(row).join(','));
      csvContent = "data:text/csv;charset=utf-8," + header + '\n' + rows.join('\n');
    } else {
      csvContent = "data:text/csv;charset=utf-8," + Object.keys(data[0]).join(',');
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  
  const handleAddEmployeeClick = () => {
    const newEmployeeCsv = [{ 'EmployeeId': '', 'FirstName': '', 'LastName': '', 'EmailId': '', 'Mobile': '', 'Pin': '', 'Amount': '', 'AmountPaid': '' }];
    createCsvFromData(newEmployeeCsv, 'AddNewEmployeeTemplate.csv');
    notify();
  };

  // const handleAddFundsClick = async () => {
  //   try {
  //     const list = await getSubcontractorsList();
  
  //     if (list.length === 0) {
  //       throw new Error('No data available to create CSV');
  //     }
  
  //     const validContractorsList = list.filter(contractor => (
  //       contractor.employeeId && contractor.firstName && contractor.lastName && contractor.emailId && contractor.mobile &&contractor.pin 
  //     ));
  
  //     console.log('Valid contractors list:', validContractorsList);
  
  //     if (validContractorsList.length === 0) {
  //       throw new Error('No valid contractors found in the list');
  //     }
  //      validContractorsList.forEach(contractor => {
  //       contractor.amount = '0';
  //       contractor.amountPaid = '0';
  //     });
  
  //     createCsvFromData(validContractorsList, 'AddFundsTemplate.csv');
  //     notify(); // Notify user of successful download
  //   } catch (error) {
  //     console.error('Error creating CSV for adding funds:', error);
  //   }
  // };

  const handleAddFundsClick = async () => {
    try {
      const list = await getSubcontractorsList();
  
      if (list.length === 0) {
        throw new Error('No data available to create CSV');
      }
  
      // No need to filter out records, include all records in the CSV
      list.forEach(contractor => {
        if (!contractor.emailId) contractor.emailId = ''; // Ensure emailId is not undefined
        if (!contractor.mobile) contractor.mobile = ''; // Ensure mobile is not undefined
        contractor.amount = '0';
        contractor.amountPaid = '0';
      });
  
      createCsvFromData(list, 'AddFundsTemplate.csv');
      notify(); // Notify user of successful download
    } catch (error) {
      console.error('Error creating CSV for adding funds:', error);
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  const [showSignoutPopup, setShowSignoutPopup] = useState(false);

  const showSignoutConfirmation = () => {
    setShowSignoutPopup(true);
  };

  const hideSignoutConfirmation = () => {
    setShowSignoutPopup(false);
  };

  const handleSignout = () => {
    // Add any necessary signout logic here
    // sessionStorage.clear();
    localStorage.clear();
    // For now, let's simulate a redirect to the login page
    window.location.href = "/";
  };

  const handleSubContractorListClick = () =>{
    navigate('/subcontractor-list');
  };

  const handleUploadCSVClick = () =>{
    navigate('/upload-csv');
  };

  const handleFileUploadStatusClick = () =>{
    navigate('/upload-file-status');
  };

  const handleDownloadTemplateClick = () =>{
    navigate('/download-template');
  };
  
  return (
    <div className={`wrapper ${isCollapsed ? 'collapse' : ''}`}>
      <div className="top_navbar">
        <div className="hamburger" onClick={toggleCollapse}>
          <img src={require('./Images/menu.png')} alt="" className="nav-menu-icon" />
        </div>
        <div className="top_menu">
          <div className="logo">Bolt Kiosk Admin Console</div>
        </div>
      </div>

      <div className="sidebar">
        <ul>
        <li>
            <a onClick={handleSubContractorListClick}>
              <span className="icon"><UilUsersAlt/></span>
              <span className="title">Subcontractor list</span>
            </a>
          </li>
          <li>
            <a onClick={handleUploadCSVClick}>
              <span className="icon"><UilFileUpload /></span>
              <span className="title">Upload CSV</span>
            </a>
          </li>
          <li>
            <a onClick={handleFileUploadStatusClick}>
              <span className="icon"><UilFileAlt /></span>
              <span className="title">Upload File Status</span>
            </a>
          </li>
          <li>
            <a onClick={handleDownloadTemplateClick}  className="active">
              <span className="icon"><UilDownloadAlt /></span>
              <span className="title">Download Template</span>
            </a>
          </li>
         
          <li>
            <a onClick={showSignoutConfirmation}>
              <span className="icon sign-out"><UilSignout /></span>
              <span className="title">Signout</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="main_container">
        <div className="main-page-header">
          <h3 className="dashboard-mainpage-h1">Download Template</h3>
        </div>
        <div className="div-center">
          <div className="upload-csv div-center">
            <div className="center-container">
              <div className="upload-form padding">
                <div className="main-page-header center-text">
                  <h3 className="dashboard-mainpage-h1">Download Template</h3>
                </div>
                <div className="template-section">
                  <p>Add New Employee</p>
                  <button className="download-btn" onClick={handleAddEmployeeClick }>Download</button>
                </div>
                <div className="template-section">
                  <p>Add Funds For Existing Employee</p>
                  <button className="download-btn" onClick={handleAddFundsClick}>Download</button>
                </div>
              </div>
              <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                // transition: Bounce,
                />
            </div>
          </div>
        </div>
      </div>
      {/* Signout Confirmation Popup */}
      {showSignoutPopup && (
        <div className="overlay">
          <div className="modal flex-form">
          <button className="close-button" onClick={hideSignoutConfirmation}>
              &times;
            </button>
            <div className='form-label'>
            <p className="popup-text">Are you sure you want to Signout?</p>
            </div>
            <div className="form-row">
              <button className="cancel-button" onClick={hideSignoutConfirmation}>
                Cancel
              </button>
              <button className="logout-button" onClick={handleSignout}>
                Signout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadTemplate;