// authUtils.js
import axios from 'axios';
import getEndpoint from '../components/Endpoint';

const validateSession = async () => {
  try {
    const response = await axios.post(
      getEndpoint('validateSessionApiEndpoint'), // Replace with your actual endpoint
      {
        // token: sessionStorage.getItem('sessionData'), // Assuming you stored the token in sessionStorage
        token: localStorage.getItem('sessionData'), // Assuming you stored the token in sessionStorage

      },
      {
        withCredentials: true,
      }
    );

    if (response.status !== 200) {
      // If the token is invalid or the session is expired, throw an error
      throw new Error('Session expired');
    }
  } catch (error) {
    // Re-throw the error to be caught in the calling code
    throw error;
  }
};

export default validateSession;