// src/components/SubcontractorList.js
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import EmployeeForm from './EmployeeForm';
import getEndpoint from './Endpoint';
import { useNavigate } from 'react-router-dom';

import '../components/CSS/Modal.css';
import '../components/CSS/Form.css';
import Pagination from './pagination';
import { CSSTransition } from 'react-transition-group';
import '../App.css';



// import { useMediaQuery } from 'react-responsive';

import { UilUsersAlt, UilFileUpload, UilFileAlt, UilDownloadAlt, UilSignout, UilUserPlus, UilExport, UilEditAlt  } from '@iconscout/react-unicons';
// import DownloadTemplate from './DownloadTemplate';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// SubcontractorList component as a functional component
const SubcontractorList = () => {

  
  // State to manage the current page and rows per page
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(15);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1); // Initialize it with 1 or the appropriate default value
  const [loading, setLoading] = useState(true);

  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedSortOption, setSelectedSortOption] = useState('Employee ID');

  
  useEffect(() => {
    // const isAuthenticated = sessionStorage.getItem('sessionData');
    const isAuthenticated = localStorage.getItem('sessionData');

    const handleBackButton = (event) => {
      if (!isAuthenticated) {
        // If not authenticated, prevent the default behavior and navigate to the login page
        event.preventDefault();
        navigate('/');
      }
    };

    // Add event listener for the browser's back button
    window.addEventListener('popstate', handleBackButton);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  const toastMessage = localStorage.getItem('toastMessage');
  if (toastMessage) {
    alert(toastMessage); // You can use your preferred way to display the message here
    // Clear the message from local storage after displaying it
    localStorage.removeItem('toastMessage');
  }
  const [employeeList, setEmployeeList] = useState([]);



  //Toast for add employee
  const notifyAddEmpSuccessful = () => {toast.success('Download Successful!', {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    // transition: Bounce,
    });
  }

  // const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const totalPages = Math.ceil(employeeList.length / rowsPerPage);
    setTotalPages(totalPages);

    // Ensure currentPage is always between 1 and totalPages
    setCurrentPage((currentPage) => Math.max(1, Math.min(currentPage, totalPages)));
}, [employeeList.length, rowsPerPage]);

  const handleNextClick = () => {
    const totalPages = Math.ceil(employeeList.length / rowsPerPage);
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages));
  };

  const handlePrevClick = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  };
  
  const [isCollapsed, setCollapsed] = useState(false);

  // Toggle the collapse state
  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  // const [employeeList, setEmployeeList] = useState([{Token:'string'}]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  // const [ setOperationAmount] = useState(0);
  const [operationAmount, setOperationAmount] = useState(0);
  // const username = sessionStorage.getItem('username');
  const username = localStorage.getItem('username');
  console.log("Username: "+username);

  const fetchEmployeeData = async () => {
    try {
      const endpoint = getEndpoint('employeeApiEndpoint');
      const response = await fetch(endpoint, {
        headers: {
          'username': username,
          'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log('Response:', data);
      setEmployeeList(data);
    } catch (error) {
      console.error('Error fetching employee data:', error.message);
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []); // Trigger the data fetching when the component mounts

  // Function to handle sort click
  const handleSortClick = (field) => {
    if (sortField === field) {
      // Toggle sort order if same field is clicked again
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set sort field and default to ascending order
      setSortField(field);
      setSortOrder('asc');
    }
  };

  // Apply search and sort filters to employee list
  useEffect(() => {
    let filteredList = [...employeeList];
    
    // Apply search filter
    if (searchQuery.trim() !== '') {
      filteredList = filteredList.filter(employee => 
        Object.values(employee).some(value => 
          typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    // Apply sort filter
    if (sortField !== '') {
      filteredList.sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];
        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setFilteredEmployeeList(filteredList);
  }, [employeeList, searchQuery, sortField, sortOrder]);
  
  // ...
    const handleAddEmployee = () => {
      setSelectedEmployee(null);
      setOperationAmount(0);
      setIsFormOpen(true);
    };
    const handleModifyEmployee = (employee) => {
      setSelectedEmployee(employee);
      setOperationAmount(0);
      setIsFormOpen(true);
    };

    const handleClose = () => {
      setShowConfirmation(false);
    };

  
    
    const handleFormSubmit = (employeeDetails) => {
      if (selectedEmployee) {
        handleUpdateEmployee(employeeDetails);
      } else {
        handleAddNewEmployee(employeeDetails);
      }
      setIsFormOpen(false);
    };

    const handleUpdateEmployee = async (employeeDetails) => {
    try {
      const response = await axios.put(getEndpoint('updateEmployeeEndpoint'), employeeDetails); // Replace 'updateEmployeeEndpoint' with the actual update endpoint
      if (!response.data.success) {
        console.error('Failed to update data:', response.status, response.statusText, response.data.message);
      } else {
        const updatedData = response.data.data;
        console.log('Data updated successfully:', updatedData);
        fetchEmployeeData(); // Refresh the employee list after update
      }
    } catch (error) {
      console.error('Network error or other issue:', error);
    }
  };


    const handleAddNewEmployee = async (employeeDetails) => {
    try {
      const response = await axios.post(getEndpoint('addEmployeeEndpoint'), employeeDetails); // Replace 'addEmployeeEndpoint' with the actual add endpoint
      if (!response.data.success) {
        console.error('Failed to add data:', response.status, response.statusText, response.data.message);
      } else {
        console.log('Data added successfully:', response.data.data);
        fetchEmployeeData(); // Refresh the employee list after adding a new employee
        notifyAddEmpSuccessful();
      }
    } catch (error) {
      console.error('Network error or other issue:', error);
    }
  };
       

    const exportToCSV = async () => {
      try {
        const response = await axios.get(`${getEndpoint('employeeApiEndpoint')}`, {
          headers: {
            'username': username,
            'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
            'Content-Type': 'application/json',
          },
        });
        console.log('Response:', response.data);
    
        const employeeList = response.data;
    
        if (employeeList.length === 0) {
          console.error('No data available for export');
          return;
        }
    
        const headers = Object.keys(employeeList[0]);
    
        // Exclude specific fields like "userId", "token", and "dateTime" from headers
        const excludedFields = ['userId', 'token', 'dateTime'];
        const filteredHeaders = headers.filter(header => !excludedFields.includes(header));
    
        const exportcsvdata = employeeList.map(employee =>
          filteredHeaders.reduce((acc, header) => {
            acc[header] = employee[header];
            return acc;
          }, {})
        );
    
        // Capitalize the headers in Title Case
        const capitalizedHeaders = filteredHeaders.map(header => {
          const words = header.split('_');
          const titleCaseHeader = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          return titleCaseHeader;
        });
    
        const csvContent =
          'data:text/csv;charset=utf-8,' +
          capitalizedHeaders.join(',') +
          '\n' +
          exportcsvdata.map(row => filteredHeaders.map(header => row[header]).join(',')).join('\n');
    
        const currentDate = new Date().toISOString().slice(0, 10);
        const fileName = `EmployeesData_${currentDate}.csv`;
    
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error fetching employee data:', error.message);
      }
    }; 

    const [showSignoutPopup, setShowSignoutPopup] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const showSignoutConfirmation = () => {
      setShowSignoutPopup(true);
    };

    const hideSignoutConfirmation = () => {
      setShowSignoutPopup(false);
    };

    const showCancelConfirmation = () => {
      setShowConfirmation(true);
    };
    const cancelConfirmed = () => {
      setShowConfirmation(false);
      setIsFormOpen(false);
    }

    const hideCancelConfirmation = () => {
      setShowConfirmation(false);
    };

    const handleSignout = () => {
      // Add any necessary signout logic here
      localStorage.clear();
      //sessionStorage.removeItem('sessionData');
      // For now, let's simulate a redirect to the login page
      window.location.href = "/";
    };

    const handleSubContractorListClick = () =>{
      navigate('/subcontractor-list');
    };

    const handleUploadCSVClick = () =>{
      navigate('/upload-csv');
    };

    const handleFileUploadStatusClick = () =>{
      navigate('/upload-file-status');
    };

    const handleDownloadTemplateClick = () =>{
      navigate('/download-template');
    };

    const handlePageClick = (page) => {
      setCurrentPage(page);
    };

    const handleSortSelect = (field, label) => {
      if (field !== '') {
        // Set sort field to the selected field
        setSortField(field);
        // Default to ascending order
        setSortOrder('asc');
        // Set the selected sort option text
        setSelectedSortOption(label);
        // Close the dropdown
        setIsOpen(false);
      }
    };
    
    const [tempSearchQuery, setTempSearchQuery] = useState('');

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleSearchButtonClick();
      }
    };    

    const handleTempSearchChange = (event) => {
      const query = event.target.value.trim().toLowerCase();
      setTempSearchQuery(query);
    
      // Check if the input field is empty
      if (query === '') {
        // Clear the search and render all data
        setTempSearchQuery('');
        setFilteredEmployeeList(employeeList);
        setCurrentPage(1); // Reset current page to 1
      }
    };

    const handleSearchButtonClick = () => {

      // Check if search query is empty
      if (!tempSearchQuery.trim()) {
        toast.error('Please enter a keyword to search.');
        return;
      }
      // Filter the employee list based on the search query
      const filteredEmployees = employeeList.filter(employee =>
        employee.employeeId.toString().includes(tempSearchQuery) ||
        employee.firstName.toLowerCase().includes(tempSearchQuery) ||
        employee.lastName.toLowerCase().includes(tempSearchQuery) ||
        employee.emailId.toLowerCase().includes(tempSearchQuery) ||
        employee.mobile.toString().includes(tempSearchQuery)
      );
    
      // Check if no data was found
      if (filteredEmployees.length === 0) {
        toast.error('No data found for the entered search query.');
        return;
      }
    
      // Update the filtered employee list state
      setFilteredEmployeeList(filteredEmployees);
    
      // Reset the current page to 1 when a new search is performed
      setCurrentPage(1);
    };
    
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            closeDropdown();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


  return (
    // Main wrapper for the SubcontractorList component
    <div className={`wrapper ${isCollapsed ? 'collapse' : ''}`}>
      
      <div className="top_navbar">
        {/* <div className={`hamburger ${isMobile ? 'disabled' : ''}`} onClick={isMobile ? null : toggleCollapseHandler}> */}
        <div className="hamburger" onClick={toggleCollapse}>
      
          <img src={require('./Images/menu.png')} alt="" className="nav-menu-icon" />
        </div>
        <div className="top_menu">
          <div className="logo">Bolt Kiosk Admin Console</div>
        </div>
      </div>

      <div className="sidebar">
        <ul>
          <li>
            <a onClick={handleSubContractorListClick} className="active">
              <span className="icon"><UilUsersAlt /></span>
              <span className="title">Subcontractor list</span>
            </a>
            
          </li>
          <li>
            <a onClick={handleUploadCSVClick}>
              <span className="icon"><UilFileUpload /></span>
              <span className="title">Upload CSV</span>
            </a>
          </li>
          <li>
            <a onClick={handleFileUploadStatusClick}>
              <span className="icon"><UilFileAlt /></span>
              <span className="title">Upload File Status</span>
            </a>
          </li>
          <li>
            <a onClick={handleDownloadTemplateClick}>
              <span className="icon"><UilDownloadAlt /></span>
              <span className="title">Download Template</span>
            </a>
          </li>
         
          <li>
            <a onClick={showSignoutConfirmation}>
              <span className="icon sign-out"><UilSignout /></span>
              <span className="title">Signout</span>
            </a>
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="main_container">
        {/* Main page header for Subcontractor List */}
        <div class="main-page-header">
          <h3 class="dashboard-mainpage-h1">Subcontractor List</h3>
          <div className='flex1'>
          <div className='search-div'>
            <div className="input-wrapper">
            <input
              className="custom-search-bar"
              type="text"
              placeholder="Enter Keyword"
              value={tempSearchQuery}
              onChange={handleTempSearchChange}
              onKeyPress={handleKeyPress}
            />
              <button className="search-button" onClick={handleSearchButtonClick}>Search</button>
            </div>
          </div>

          <div className="dropdown-container" ref={dropdownRef}>
            <button className="dropdown-link" onClick={toggleDropdown}>
              <span>{selectedSortOption}</span>
              <i className={`fa ${isOpen ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
            </button>
            <CSSTransition
              in={isOpen}
              timeout={300}
              classNames="dropdown-animation"
              unmountOnExit
            >
              <div className="dropdown-box">
                <ul>
                  <li><a className="data-link" onClick={() => handleSortSelect('employeeId', 'Employee ID')}><span>Employee ID</span></a></li>
                  <li><a className="data-link" onClick={() => handleSortSelect('firstName', 'First Name')}><span>First Name</span></a></li>
                  <li><a className="data-link" onClick={() => handleSortSelect('lastName', 'Last Name')}><span>Last Name</span></a></li>
                  <li><a className="data-link" onClick={() => handleSortSelect('emailId', 'Email Id')}><span>Email Id</span></a></li>
                </ul>
              </div>
            </CSSTransition>
          </div>
            <button class="add-employee-btn" onClick={handleAddEmployee}>
              <UilUserPlus class="icon" /> &nbsp; <div class="display-none">Add Employee</div>
            </button>
          </div>

          {/* Add other components or buttons if needed */}
        </div>


        

        {/* Table for displaying subcontractor data */}
        <div className="list-table table-container-1">
          <table>
            {/* Table headers */}
            <thead>
              <tr>
                <th className='orange'>Employee ID</th>
                <th className='orange'>First Name</th>
                <th className='orange'>Last Name</th>
                <th className='orange'>Email</th>
                <th className='orange'>Mobile</th>
                <th className='orange'>Authorized Amount</th>
                <th className='orange'>Amount Paid</th>
                <th className='orange'>Actions</th>
              </tr>
            </thead>

                {/* Table body with dynamic data */}
          <tbody>
          {filteredEmployeeList.length === 0 ? (
            <tr className='no-data-message'>
              <td colSpan="14">
                <div className="centered">
                  <h1>No data available Please add Employee</h1>
                </div>
              </td>
            </tr>
          ) : (filteredEmployeeList
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((employee) => (
                <tr key={employee.employeeId}>
                  <td>{employee.employeeId}</td>
                  <td>{employee.firstName}</td>
                  <td>{employee.lastName}</td>
                  <td>{employee.emailId ? employee.emailId : "Email not provided"}</td>
                  <td>{employee.mobile ? employee.mobile : "Mobile number not provided"}</td>
                  <td>${employee.amount}</td>
                  <td>${employee.amountPaid}</td>
                  <td>
                    <button className="manage-employee-btn" onClick={() => handleModifyEmployee(employee)}>
                      <UilEditAlt  className="icon"  style={{ height: '18px' }}/> &nbsp; <div className="display-none">Manage</div>
                    </button>
                  </td>
                </tr>
              ))
          )}
          </tbody>
          </table>    
        </div>
        
       {/* Render the Pagination component */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
        handlePageClick={handlePageClick}
      />      
        <div className="main-page-header top">
          <h3 class="dashboard-mainpage-h1"> </h3>
          <button className="add-employee-btn" onClick={exportToCSV}>
            <UilExport className="icon" /> &nbsp; <div className="display-none">Export for ACH</div>
          </button>
          {/* Add other components or buttons if needed */}
        </div>
      </div>

      <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                // transition: Bounce,
                />
      
      
      {/* {showModal && (
        <div className="overlay">
          <div className="modal">
            <button className="close-button" onClick={handleCloseModal}>
              &times;
            </button>

            <EmployeeForm
              onSubmit={handleFormSubmit}
              onCancel={() => setIsFormOpen(false)}
              employee={selectedEmployee}
              isModifyMode={!!selectedEmployee}
              isModifyHeading={true}
            />
            
          </div>
        </div>
      )} */}

      {isFormOpen && (
        <div className="overlay">
        <div className="modal">
        <button className="close-button" onClick={showCancelConfirmation}>
              &times;
            </button>

          <EmployeeForm
            onSubmit={handleFormSubmit}
            onCancel={() => setIsFormOpen(false)}
            employee={selectedEmployee}
            isModifyMode={!!selectedEmployee}
            isModifyHeading={true}
          />

<ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
          
        </div>
      </div>
      )}

      {/* Signout Confirmation Popup */}
      {showSignoutPopup && (
        <div className="overlay">
          <div className="modal flex-form">
          <button className="close-button" onClick={hideSignoutConfirmation}>
              &times;
            </button>
            <div className='form-label'>
            <p className="popup-text">Are you sure you want to Signout?</p>
            </div>
            <div className="form-row">
              <button className="cancel-button" onClick={ hideSignoutConfirmation}>
              Cancel
              </button>
              <button className="logout-button" onClick={ handleSignout}>
              Signout
              </button>
            </div>
          </div>
        </div>
      )}  

      {/* Signout Confirmation Popup */}
      {showConfirmation && (
        <div className="overlay">
          <div className="modal flex-form">
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
            <div className='form-label'>
              <p className="popup-text">Do you want to exit without changes?</p>
            </div>
            <div className="form-row">
              <button className="cancel-button" onClick={hideCancelConfirmation}>
                No
              </button>
              <button className="logout-button" onClick={cancelConfirmed}>
              Yes
              </button>
            </div>
          </div>
        </div>
      )}

    </div>    
  );
};

export default SubcontractorList;
