import React, { useState } from 'react';
import '../components/CSS/Form.css';

const DeleteConfirmationPopup = ({ handleDelete, handleCancel, employeeId }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleDeleteClick = () => {
    setIsClicked(true); // Set isClicked to true when delete button is clicked
    handleDelete(employeeId);
  };

  return (
    <div className="overlay">
      <div className="modal flex-form">
        <button className="close-button" onClick={handleCancel}>
          &times;
        </button>
        <div className="form-label">
          <p className="popup-text">Confirm deletion of this employee?</p>
        </div>
        <div className="form-row">
          <button className="cancel-button" onClick={handleCancel}>
            Cancel
          </button>
          <button 
            className="logout-button" 
            onClick={handleDeleteClick} // Call handleDeleteClick instead of handleDelete directly
            disabled={isClicked} // Disable button if isClicked is true
          >
            {isClicked ? 'Deleting...' : 'Delete'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;
