import React, { useState,useEffect } from 'react';
import './CSS/Registration.css';
import { useNavigate ,  useHistory} from 'react-router-dom';
import getEndpoint from './Endpoint';



const Registration = () => {
 
  const navigate = useNavigate();
  //const history = useHistory(); // Use useHistory instead of history from the props

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  
  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener('popstate', () => {
        window.history.pushState(null, null, window.location.href);
      });
    };
    disableBackButton(); // Call the function when the component mounts
    return () => {
      window.removeEventListener('popstate', () => {
        window.history.pushState(null, null, window.location.href);
      });
    };
  }, []);

    // const unblock = history.block((location, action) => {
    //   if (action === 'POP') {
    //     disableBackButton(); // Call the function when the user tries to go back
    //     return false;
    //   }
    // });
  
  //   return () => {
  //     unblock(); // Cleanup to stop blocking when the component unmounts
  //   };
  // }, [history]);
  useEffect(() => {
   // const isAuthenticated = sessionStorage.getItem('sessionData');
    const isAuthenticated = localStorage.getItem('sessionData');
    if (isAuthenticated) {
      navigate('/subcontractor-list');
    }
  }, [navigate]);

  useEffect(() => {
    const handleNavigation = (event) => {
      event.preventDefault();
    };
  
    // Disable back and forward navigation
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handleNavigation);
  
    return () => {
      // Enable navigation when the component is unmounted
      window.removeEventListener('popstate', handleNavigation);
    };
  }, []);
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleTogglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault(); // Prevent entering space character
    }
  };
  const handleLogin = async () => {
    try {

      const trimmedUsername = username.trim();
       if(!trimmedUsername || trimmedUsername !==username)
       {
        setError('Invalid username or password');
        setTimeout(() => {
          setError('');
        }, 5000); // Timeout for 5 seconds
        return;
       }
      const trimmedPassword = password.trim(); // Trim leading and trailing spaces
      if (!trimmedPassword || trimmedPassword !== password) {
        setError('Invalid username or password');
        setTimeout(() => {
          setError('');
        }, 5000); // Timeout for 5 seconds
        return;
      }
  
      const loginEndpoint = getEndpoint('loginApiEndpoint');
      const response = await fetch(loginEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailId: username,
          password: trimmedPassword,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('sessionData', data.token);
        localStorage.setItem('username', username);
        localStorage.setItem('userId', data.userId);
        // sessionStorage.setItem('sessionData', data.token);
        // sessionStorage.setItem('username', username);
        // sessionStorage.setItem('userId', data.userId);
        navigate('/subcontractor-list');
      } else {
        setError('Invalid username or password');
        setTimeout(() => {
          setError('');
        }, 5000); // Timeout for 5 seconds
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      setError('An error occurred during authentication');
      setTimeout(() => {
        setError('');
      }, 5000); // Timeout for 5 seconds
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };
  return (
    <div className="container-fluid">
      <div className='center-of-page'>
      <div className="row-1">
        <div className="col-md-6 img bolt">
          {/* Left Image */}
          <img src={require('./Images/BOLT KIOSK.png')} alt="left"
          style={{ width: '100%', maxWidth: '200px', marginLeft: '50px' }}
          />
        </div>
        <div className="col-md-6 img admin">
          {/* Right Image */}
          <img
            src={require('./Images/ADMIN CONSOLE.png')}
            alt="right"
            style={{ width: '100%', maxWidth: '150px', marginRight: '50px' }}
          />
        </div>
      </div>
      </div>
      {/* <h2 style={{ fontSize: '32px', color: '#E55604', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>
        Bolt Kiosk
      </h2> */}
      <div className="row" style={{ height: '80vh' }}>
        <div className="col-md-6 text-center"></div>
        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="login-container">
            <h2 className="mt-4" style={{ fontSize: '24px' }} >Admin Login</h2>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  id="username"
                  name="username"
                  required
                  placeholder="Username"
                  value={username}
                  onKeyDown={handleKeyDown} 
                  onChange={handleUsernameChange}
                  style={{ fontSize: '18px', padding: '0px 0px 0px 45px' }}
                />
                <i className="fa fa-user-o" style={{ position: 'absolute', left: '25px', top: '50%', transform: 'translateY(-50%)', fontSize: '24px' }}></i>
              </div>
              <div className="form-group mt-5" style={{ position: 'relative' }}>
              <i className="fa fa-keyboard-o" style={{ position: 'absolute', left: '25px', top: '50%', transform: 'translateY(-50%)', fontSize: '24px' }}></i>
              <input
                type={passwordVisible ? 'text' : 'password'}
                id="password"
                name="password"
                required
                placeholder="Password"
                value={password}
                setPasswordVisible="false"
                onKeyDown={handleKeyDown} 
                onChange={handlePasswordChange}
                style={{
                  fontSize: '18px',
                  padding: '0px 0px 0px 45px',  // Updated padding to move the icon to the right end
                  appearance: 'none',
                }}
              />
              <i
                className={`fa ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                style={{
                  position: 'absolute',
                  right: '16px',  // Positioned to the right
                  top: '51%',
                  transform: 'translateY(-50%)',
                  color: '#000',
                  fontSize: '24px',
                  cursor: 'pointer',
                }}
                onClick={handleTogglePasswordVisibility}
              ></i>
            </div>
              <div className="form-group">
                <button type="submit">LOG IN</button>
              </div>
            </form>
            {error && <div className="error-message">{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Registration;