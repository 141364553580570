// src/App.js
import React, { useEffect } from 'react';
import RouterConfig from "./utils/routing";
import validateSession from './utils/authUtils'; // Update the path accordingly

const App = () => {
  useEffect(() => {
    const checkSession = async () => {
      try {
        await validateSession();
        // Continue rendering your application if the session is valid
      } catch (error) {
        // Handle the error or redirect to login page
        console.error('Error:', error);

        // Check if the error is due to session expiration
        if (error.message === 'Session expired') {
          // Clear the session data and redirect to login page only if the user is authenticated
          if (localStorage.getItem('sessionData')) {
           // sessionStorage.removeItem('sessionData');
            localStorage.clear();
            window.location.href = '/'; // Replace with the login page URL
          }
        } else {
          // For other errors, redirect to login page only if the user is authenticated
          // if (sessionStorage.getItem('sessionData')) {
          //   window.location.href = '/'; // Redirect to login page
          // }
        }
      }
    };

    const resetSessionTimeout = () => {
      // Set a new timeout for session expiration (adjust the time as needed)
      setTimeout(() => {
        // Redirect to login page on session expiration only if the user is authenticated
        if (localStorage.getItem('sessionData')) {
           localStorage.clear();
        // sessionStorage.removeItem('sessionData');
          window.location.href = '/'; // Replace with the login page URL
        }
      }, 3600000); // 60,000 milliseconds = 1 minute
    };

    // Initial check on component mount
    checkSession();

    // Reset the session timeout on user activity
    document.addEventListener('mousemove', resetSessionTimeout);

    // Clean up the timeout and event listener on component unmount
    return () => {
      document.removeEventListener('mousemove', resetSessionTimeout);
    };
  }, []);

  return (
    <RouterConfig />
  );
};

export default App;
