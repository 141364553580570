// UploadFileStatus.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UploadStatusTable from './UploadStatusTable';
import { UilUsersAlt, UilFileUpload, UilFileAlt, UilDownloadAlt, UilSignout, UilExport } from '@iconscout/react-unicons';
import getEndpoint from './Endpoint';
import { useNavigate } from 'react-router-dom';
import Pagination from './pagination';


const UploadFileStatus = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(15);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [isCollapsed, setCollapsed] = useState(false);
  // const username = sessionStorage.getItem('username');
  const username = localStorage.getItem('username');

  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1); // Initialize it with 1 or the appropriate default value



  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${getEndpoint('uploadFileStatusApiEndpoint')}`, {
          headers: {
            'username': username,
             'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
            'Content-Type': 'application/json',
          },
        });

        setEmployeeList(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeData();
  }, [username]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     const message = "Are you sure you want to leave?";
  //     event.returnValue = message;
  //     return message;
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);


  const handleNextClick = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };
//   const exportToCSV = async () => {
//     try {
//         setLoading(true);
//         const response = await axios.get(`${getEndpoint('uploadFileStatusApiEndpoint')}`, {
//             headers: {
//                 'username': username,
//                 'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
//                 'Content-Type': 'application/json',
//             },
//         });
//         const employeeList = response.data;
//         if (employeeList.length === 0) {
//             console.error('No data available for export');
//             return;
//         }
//         // Fields to exclude from export
//         const excludedFields = ['userid', 'token', 'datetime' ];
//         // Filter out excluded fields from headers
//         const filteredHeaders = Object.keys(employeeList[0]).filter(key =>
//             !excludedFields.includes(key.toLowerCase())
//         );
//         // Filter out excluded fields from data
//         const exportCSVData = employeeList.map(employee => {
//             const filteredEmployee = {};
//             // Copy all fields except excluded ones
//             Object.keys(employee).forEach(key => {
//                 if (!excludedFields.includes(key.toLowerCase())) {
//                     filteredEmployee[key] = employee[key];
//                 }
//             });
//             return filteredEmployee;
//         });
//         // Map headers to capitalize the first letter
//         const capitalizedHeaders = filteredHeaders.map(header => header.charAt(0).toUpperCase() + header.slice(1));
//         const csvContent =
//             'data:text/csv;charset=utf-8,' +
//             capitalizedHeaders.join(',') +
//             '\n' +
//             exportCSVData.map(row => filteredHeaders.map(header => row[header]).join(',')).join('\n');
//         const currentDate = new Date().toISOString().slice(0, 10);
//         const fileName = `RecentFileUploadData_${currentDate}.csv`;
//         const encodedUri = encodeURI(csvContent);
//         const link = document.createElement('a');
//         link.setAttribute('href', encodedUri);
//         link.setAttribute('download', fileName);
//         document.body.appendChild(link);
//         link.click();
//     } catch (error) {
//         console.error('Error exporting data:', error);
//     } finally {
//         setLoading(false);
//     }
// };

// changes//
const exportToCSV = async () => {
  try {
      setLoading(true);
      const response = await axios.get(`${getEndpoint('uploadFileStatusApiEndpoint')}`, {
          headers: {
              'username': username,
              'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
              'Content-Type': 'application/json',
          },
      });
      const employeeList = response.data;
      if (employeeList.length === 0) {
          console.error('No data available for export');
          return;
      }
      // Exclude 'datetime' field from headers and data
      const excludedFields = ['datetime', 'userId', 'token'];

      const filteredHeaders = Object.keys(employeeList[0]).filter(header => !excludedFields.includes(header));

      const exportCSVData = employeeList.map(row => {
          const filteredRow = {};
          filteredHeaders.forEach(header => {
              filteredRow[header] = row[header];
          });
          return filteredRow;
      });

      // Map headers to capitalize the first letter
      const capitalizedHeaders = filteredHeaders.map(header => header.charAt(0).toUpperCase() + header.slice(1));
      // Construct CSV content
      const csvContent =
          'data:text/csv;charset=utf-8,' +
          capitalizedHeaders.join(',') +
          '\n' +
          exportCSVData.map(row => filteredHeaders.map(header => row[header]).join(',')).join('\n');
      // Create a download link and trigger download
      const currentDate = new Date().toISOString().slice(0, 10);
      const fileName = `RecentFileUploadData_${currentDate}.csv`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
  } catch (error) {
      console.error('Error exporting data:', error);
  } finally {
      setLoading(false);
  }
};
  
  useEffect(() => {
    const totalPages = Math.ceil(employeeList.length / rowsPerPage);
    setTotalPages(totalPages);

    // Ensure currentPage is always between 1 and totalPages
    setCurrentPage((currentPage) => Math.max(1, Math.min(currentPage, totalPages)));
}, [employeeList.length, rowsPerPage]);

  const [showSignoutPopup, setShowSignoutPopup] = useState(false);

  const showSignoutConfirmation = () => {
    setShowSignoutPopup(true);
  };

  const hideSignoutConfirmation = () => {
    setShowSignoutPopup(false);
  };

  const handleSignout = () => {
    // Add any necessary signout logic here
    // sessionStorage.clear();
    localStorage.clear();

    // For now, let's simulate a redirect to the login page
    window.location.href = "/";
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleSubContractorListClick = () =>{
    navigate('/subcontractor-list');
  };

  const handleUploadCSVClick = () =>{
    navigate('/upload-csv');
  };

  const handleFileUploadStatusClick = () =>{
    navigate('/upload-file-status');
  };

  const handleDownloadTemplateClick = () =>{
    navigate('/download-template');
  };
 


  return (
    <div className={`wrapper ${isCollapsed ? 'collapse' : ''}`}>
      <div className="top_navbar">
        <div className="hamburger" onClick={toggleCollapse}>
          <img src={require('./Images/menu.png')} alt="" className="nav-menu-icon" />
        </div>
        <div className="top_menu">
          <div className="logo">Bolt Kiosk Admin Console</div>
        </div>
      </div>

      <div className="sidebar">
        <ul>
        <li>
            <a onClick={handleSubContractorListClick}>
              <span className="icon"><UilUsersAlt/></span>
              <span className="title">Subcontractor list</span>
            </a>
          </li>
          <li>
            <a onClick={handleUploadCSVClick}>
              <span className="icon"><UilFileUpload /></span>
              <span className="title">Upload CSV</span>
            </a>
          </li>
          <li>
            <a onClick={handleFileUploadStatusClick} className="active">
              <span className="icon"><UilFileAlt /></span>
              <span className="title">Upload File Status</span>
            </a>
          </li>
          <li>
            <a onClick={handleDownloadTemplateClick}>
              <span className="icon"><UilDownloadAlt /></span>
              <span className="title">Download Template</span>
            </a>
          </li>
         
          <li>
            <a onClick={showSignoutConfirmation}>
              <span className="icon sign-out"><UilSignout /></span>
              <span className="title">Signout</span>
            </a>
          </li>
        </ul>
      </div>

      <div className="main_container">
        <div className="main-page-header">
          <h3 className="dashboard-mainpage-h1">Upload File Status</h3>
        </div>

        <div className="list-table table-container-1">
          <table>
            <thead>
              <tr>
                <th className='orange'>Employee ID</th>
                <th className='orange'>First Name</th>
                <th className='orange'>Last Name</th>
                <th className='orange'>Email</th>
                <th className='orange'>Mobile</th>
                <th className='orange'>Authorized Amount</th>
                <th className='orange'>Amount Paid</th>
                <th className='orange'>Status</th>
              </tr>
            </thead>

            <UploadStatusTable jsonData={employeeList} currentPage={currentPage} recordsPerPage={rowsPerPage} />
          </table>
        </div>

       {/* Render the Pagination component */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePrevClick={handlePrevClick}
        handleNextClick={handleNextClick}
        handlePageClick={handlePageClick}
      />
        <div className="main-page-header top">
          <h3 className="dashboard-mainpage-h1"> </h3>
          <button className="add-employee-btn" onClick={exportToCSV}>
            <UilExport className="icon" /> &nbsp; <div className="display-none">Export for ACH</div>
          </button>
        </div>
      </div>
      {/* Signout Confirmation Popup */}
      {showSignoutPopup && (
        <div className="overlay">
          <div className="modal flex-form">
            <button className="close-button" onClick={hideSignoutConfirmation}>
              &times;
            </button>
            <div className='form-label'>
              <p className="popup-text">Are you sure you want to Signout?</p>
            </div>
            <div className="form-row">
              <button className="cancel-button" onClick={hideSignoutConfirmation}>
                Cancel
              </button>
              <button className="logout-button" onClick={handleSignout}>
              Signout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFileStatus;