import React, { Component } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Registration from "../components/Registration";
import SubcontractionList from "../components/SubcontractorList";
import DownloadTemplate from "../components/DownloadTemplate";
import UploadCSV from "../components/UploadCSV";
import UploadFileStatus from "../components/UploadFileStatus";



class RouterConfig extends Component {
  componentDidMount() {
    window.addEventListener("popstate", this.onBackButtonEvent());
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackButtonEvent());
  }

  onBackButtonEvent = () => {
    window.history.forward();
  };

  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Registration />} />
          <Route path="/subcontractor-list" element={<SubcontractionList />} />
          <Route path="/download-template" element={<DownloadTemplate />} />
          <Route path="/upload-csv" element={<UploadCSV />} />
          <Route path="/upload-file-status" element={<UploadFileStatus />} />
         

        </Routes>
      </Router>
    );
  }
}

export default RouterConfig;