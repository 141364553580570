import config from '../components/Config.json';
const getEndpoint = (key) => {
    const baseApiUrl = config.baseApiUrl;
    const endpoints = {
        'loginApiEndpoint': 'Authentication/login',
       ' validateSessionApiEndpoint' : 'Authentication/validateSession',
        'employeeApiEndpoint': 'Employee',
        'deleteEmployeeEndpoint': 'ModifyEmployee/DeleteEmployee',
        'updateEmployeeEndpoint': 'EmployeeID',
        'addEmployeeEndpoint': 'ModifyEmployee/AddEmployee',
        'uploadcsvApiEndpoint': 'FileUpload/upload',
        'uploadFileStatusApiEndpoint': 'FileUpload/GetUploadFileStatus',
        'addEmployeeApiEndpoint': 'ModifyEmployee/AddEmployee',
        'updateEmployeeApiEndpoint': 'ModifyEmployee/UpdateEmployee',
        'deleteEmployeeApiEndpoint': 'ModifyEmployee/DeleteEmployee',
    };
    const endpointKey = endpoints[key];
    if (!endpointKey) {
        console.error(`Endpoint not found for key: ${key}`);
        return null;
    }
    return `${baseApiUrl}${endpointKey}`;
};
export default getEndpoint;