import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../components/CSS/Form.css';
import getEndpoint from './Endpoint';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteConfirmationPopup from './DeleteConfirmationPopup';

const EmployeeForm = ({ onSubmit, onDelete, onCancel, employee, isModifyMode }) => {
  const [employeeDetails, setEmployeeDetails] = useState(employee || {});
  const [emailError, setEmailError] = useState(null);
  const navigate = useNavigate();
  const [amountError, setAmountError] = useState(null);
  const [authorizedAmountError, setAuthorizedAmountError] = useState(null);
  const [deductAmountError, setDeductAmountError] = useState(null);
  const [mobileError, setMobileError] = useState(null);
  const [pinError, setPinError] = useState(null);
  const [employeeIdError, setEmployeeIdError] = useState(null);
  const [operationAmount, setOperationAmount] = useState('');
  const [successMessage, setSuccessMessage] = useState(null); 
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false); 
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  // const username = sessionStorage.getItem('username');
  const username = localStorage.getItem('username');

  const [employeeList, setEmployeeList] = useState([]);
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);
  const [isAddClicked, setIsAddClicked] = useState(false);

  
  useEffect(() => {
    setEmployeeDetails(employee || {});
  }, [employee]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'employeeId') {
      // Restrict input to 4 digits
      const newValue = value.slice(0, 4);
      setEmployeeDetails((prevDetails) => ({ ...prevDetails, [name]: newValue }));
      return;
    }
    if (name === 'employeeId' && value.length === 1 && value[0] === '0') {
        setEmployeeDetails((prevDetails) => ({ ...prevDetails, [name]: '' }));
        return;
    }
    
    if(name === 'email'){
      const emailRegex = /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) {
        return 'Invalid email format.';
      }
      if (value.startsWith('.') || value.endsWith('.')) {
        return 'Email address cannot start or end with a period.';
      }
      if (/[.]{2,}/.test(value.split('@')[0])) {
        return 'Consecutive periods are not allowed in the local part.';
      }
    }
    if (name === 'emailId') {
      const emailValidationResult = validateEmail(value);
      setEmailError(emailValidationResult);
    }
    if (name === 'mobile' || name === 'pin') {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        return; 
      }
    }
  
  //  if (name === 'mobile' || name === 'pin') {
  //   let newValue = value.replace(/\D/g, ''); // Remove all non-digit characters

  //   // If the value starts with '-' or '+', clear the input value
  //   if (newValue.startsWith('-') || newValue.startsWith('+')) {
  //     newValue = '';
  //   }
  //   setEmployeeDetails((prevDetails) => ({ ...prevDetails, [name]: newValue }));
  //   return;
  // }
    
 
  if (name === 'mobile') {
      if (parseFloat(value) < 0) {
        setMobileError('Mobile number cannot be negative');
        return;
      }
      if (value.length > 10) {
        // setMobileError('Mobile number must be exactly 10 digits.');
        return;
      }
      const mobileValidationResult = validateMobile(value);
      setMobileError(mobileValidationResult);
    }
    if (name === 'pin') {
        if (parseFloat(value) < 0) {
            setPinError('Pin number cannot be negative');
            return;
        }
        if (value.length <= 6) {
          setEmployeeDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
      }
        // Allow more than 6 digits to be entered without validation
        if (value.length > 6) {
            return;
        }
        const pinValidationResult = validatePin(value);
        setPinError(pinValidationResult);

        // Remove any periods from the value
        const sanitizedValue = value.replace('.', '');
        // Update the pin value
        setEmployeeDetails((prevDetails) => ({ ...prevDetails, [name]: sanitizedValue }));
    } 

    if (name === 'amount') {
      if (parseFloat(value) < 0) {
          setAmountError('Amount cannot be negative');
          return;
      }
      if (value.includes('.')) {
          setAmountError('Amount cannot contain decimals');
          return;
      }
      setAmountError(null);
  }
  
    setEmployeeDetails((prevDetails) => ({ ...prevDetails, [name]: value }));

    if (employeeDetails[name] !== value) {
        setIsChangesMade(true);
    } 
    else {
        setIsChangesMade(false);
    }
    validateForm();
};



  const validateForm = () => {
    const isValid = !emailError && !amountError && !mobileError && !pinError;
    setIsValidForm(isValid);
  };

  const handleOperationAmountChange = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/^0+/, '');
    const numericValue = parseFloat(newValue) || 0;
    if (newValue === '' || newValue === '-') {
      setOperationAmount('');
      setDeductAmountError('');
      setIsChangesMade(true);
      return;
    }
    const isNegative = numericValue < 0;
    if (isNegative) {
      const resultingAmount = employeeDetails.amount + numericValue;
      if (resultingAmount < 0) {
        setDeductAmountError("You are trying to deduct more than the authorized amount.");
        setOperationAmount('');
        setIsChangesMade(true);
        return;
      }
    }
    if (newValue.includes('.')) {
      setDeductAmountError('Decimal value entered for add/deduct amount.');
    } else {
      setDeductAmountError('');
    }
    setOperationAmount(numericValue.toString());
    setIsChangesMade(true);
  };

 
  const handlePinChange = (value) => {

    if (value.length === 0) {
      setPinError('');
      setEmployeeDetails((prevDetails) => ({ ...prevDetails, pin: '' })); // Clear the pin value
      return;
    }
      if (parseFloat(value) < 0) {
        setPinError('Pin number cannot be negative');
        return;
      }
      // Allow more than 6 digits to be entered without validation
      if (value.length > 6) {
        return;
      }
      
      const pinValidationResult = validatePin(value);
      setPinError(pinValidationResult);
    
    setEmployeeDetails((prevDetails) => ({ ...prevDetails, pin: value.slice(0, 6) }));
  }

  const handlePinBlur = (value) => {
    const pinValidationResult = validatePin(value);
    setPinError(pinValidationResult);
  };

  const validatePin = (value) => {
    if (value.length === 0) {
      setPinError('');
      setEmployeeDetails((prevDetails) => ({ ...prevDetails, pin: '' })); // Clear the pin value
      return;
    }
    if (value === '000000') {
      return 'Pin cannot be "000000".';
    }
    if (!/^\d{6}$/.test(value)) {
      return 'Pin number must be exactly 6 digits.';
    }
    if(value.length>6){
      return;
    }
    
    return null;
  };

  const validateEmail = (value) => {
    if (!value) {
      return null; // Return null if no email is entered
    }
  
    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      return 'Invalid email format.';
    }
    if (value.startsWith('.') || value.endsWith('.')) {
      return 'Email address cannot start or end with a period.';
    }
    if (/[.]{2,}/.test(value.split('@')[0])) {
      return 'Consecutive periods are not allowed in the local part.';
    }
    return null; // Return null if email is valid
  };
  
  const validateMobile = (value) => {
    if (!value) {
      return null; // Return null if no mobile number is entered
    }
    if (!/^\d{10}$/.test(value)) {
      return 'Mobile number must be exactly 10 digits.';
    }
    if (value === '0000000000') {
      return 'Mobile number cannot be "0000000000".';
    }
    return null; // Return null if mobile number is valid
  };
  

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (event, name) => {
    // Prevent pasting
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      event.preventDefault();
    }
  
    // Prevent entering space character
    if (event.key === ' ') {
      event.preventDefault();
    }
  
    // Prevent keydown event for the "pin" field if the '-' key is pressed
    if ((name === 'mobile' || name === 'pin') && !/^\d$/.test(event.key)) {
      event.preventDefault();
    }
  
    if ((name === 'mobile' || name === 'pin') && (event.key === '-' || event.key === '+')) {
      event.preventDefault();
    }

    // Allow '.' character for email field
    if (name === 'emailId' && event.key === '.') {
      // Check if the current input value already contains a '.' to avoid duplicate '.' characters
      if (event.target.value.includes('@') && event.target.value.includes('.')) {
        event.preventDefault();
      }
    }
  };
  
  
  
  

  const handleDeleteClick = async () => {
    setShowDeletePopup(true); 
  };

  const handleCancel = () => {
    setShowDeletePopup(false);
  };
  
  const validateEmployeeId = (value) => {
      if (!/^\d{1,4}$/.test(value)) {
      return 'Employee ID must be exactly 4 numbers.';
    }
    return null;
  };
  
  const handleSubmit = async (e) => {

    if (isAddClicked) {
      return;
    }
    setIsAddClicked(true);

    e.preventDefault();

    if (employeeDetails.mobile && employeeDetails.mobile.length < 10) {
      setIsAddClicked(false);
      // Handle the case where mobile number is less than 10 digits
      toast.error('Mobile number must be at least 10 digits.');
      // You can display an error message or handle the situation as needed
      return;
    }
  
    // Check if mobile, PIN, or amount contains "."
    if (employeeDetails.mobile && employeeDetails.mobile.includes('.') ||
        employeeDetails.pin && employeeDetails.pin.includes('.') ||
        employeeDetails.amount && employeeDetails.amount.toString().includes('.')) {
      // Set error message
      setIsAddClicked(false);
      setAmountError('Mobile number, PIN, or amount cannot contain a decimal point.');
      return;
    } else {
      setIsAddClicked(true);
      setAmountError(null);
    }
  
    // Validate employee ID and PIN
    const employeeIdValidationResult = validateEmployeeId(employeeDetails.employeeId);
    const pinValidationResult = validatePin(employeeDetails.pin);
  
    if (employeeIdValidationResult || pinValidationResult) {
      setEmployeeIdError(employeeIdValidationResult);
      setPinError(pinValidationResult);
      setIsAddClicked(false);
      return;
    }
  
    if (employeeDetails.emailId) {
      const emailValidationResult = validateEmail(employeeDetails.emailId);
      if (emailValidationResult) {
        setEmailError(emailValidationResult);
        setIsAddClicked(false);
        return;
      }
    }
    
    // Continue with adding the employee
    try {
      const requestData = {
        EmployeeId: parseInt(employeeDetails.employeeId, 10) || 0,
        FirstName: String(employeeDetails.firstName),
        LastName: String(employeeDetails.lastName),
        EmailId: employeeDetails.emailId ? String(employeeDetails.emailId) : '', // Validate email only if provided
        Mobile: employeeDetails.mobile ? String(employeeDetails.mobile) : '', // Validate mobile only if provided
        Pin: String(employeeDetails.pin).slice(0, 6),
        Amount: parseFloat(employeeDetails.amount) || 0,
        UserId: parseInt(employeeDetails.UserId, 10) || 0,
        Token: String(employeeDetails.Token)
      };
  
      // const username = sessionStorage.getItem('username');
      const username = localStorage.getItem('username');

      const addEmployeeResponse = await fetch(`${getEndpoint('addEmployeeApiEndpoint')}`, {
        method: 'POST',
        headers: {
          'username': username,
          'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (!addEmployeeResponse.ok) {
        setIsAddClicked(false);
        console.error('Failed to add data:', addEmployeeResponse.status, addEmployeeResponse.statusText);
        // Handle the error scenario as needed
      } else {
        setIsAddClicked(true);
        console.log('Data added successfully:', addEmployeeResponse);
      }
    } catch (error) {
      setIsAddClicked(false);
      console.error('Network error or other issue:', error);
      // Handle the error scenario as needed
    }
  
    toast.success("Employee added successfully");
    setTimeout(() => {
      navigate('/subcontractor-list', { replace: true });
      window.location.reload();
    }, 3000); 
  }; 
  
  const fetchEmployeeData = async () => {
    try {
      const endpoint = getEndpoint('employeeApiEndpoint');
      const response = await fetch(endpoint, {
        headers: {
          'username': username,
          'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log('Response:', data);
      setEmployeeList(data);
    } catch (error) {
      console.error('Error fetching employee data:', error.message);
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []); // Trigger the data fetching when the component mounts

  

  const handleUpdate = async () => {

    if (isUpdateClicked) {
      return;
    }
    setIsUpdateClicked(true);
    
    const hasSpaces = employeeDetails.firstName.includes(' ') || employeeDetails.lastName.includes(' ');
    if (hasSpaces) {
      setIsUpdateClicked(false);
      return;
    }

    const hasSpecialCharacters = /[^\w\s]/.test(employeeDetails.firstName) || /[^\w\s]/.test(employeeDetails.lastName);
    if (hasSpecialCharacters) {
      setIsUpdateClicked(false);
      return;
    }

    const hasNumbers = /\d/.test(employeeDetails.firstName) || /\d/.test(employeeDetails.lastName);
      if (hasNumbers) {
        setIsUpdateClicked(false);
        return;
    }   

    if (employeeDetails.emailId) {
      const emailValidationResult = validateEmail(employeeDetails.emailId);
      if (emailValidationResult) {
        setIsUpdateClicked(false);
        setEmailError(emailValidationResult);
        return;
      }
    }
   
    if (employeeDetails.amount % 1 !== 0) {
      setIsUpdateClicked(false);
      console.log('Decimal value entered for authorized amount.');
      setAuthorizedAmountError('Authorized amount must be a round figure (no decimal values).');
      return; 
    } 
    else {
      setIsUpdateClicked(false);
      console.log('Authorized amount is valid.');
      setAuthorizedAmountError(null); 
    }

    if (operationAmount && operationAmount % 1 !== 0) {
      setIsUpdateClicked(false);
      console.log('Decimal value entered for add/deduct amount.');
      setDeductAmountError('Add/deduct amount must be a round figure (no decimal values).');
      return; 
    } 

    else {
      console.log('Add/deduct amount is valid.');
      // setIsUpdateClicked(false);
      setDeductAmountError(null); 
    }

    if (
      !employeeDetails.employeeId ||
      !employeeDetails.firstName ||
      !employeeDetails.lastName ||
      // !employeeDetails.emailId ||
      // !employeeDetails.mobile ||
      !employeeDetails.pin 
      // !employeeDetails.amount
    ) {
      setIsUpdateClicked(false);
      console.error('One or more required fields are empty. Cannot update employee.');
      return;
    }

      // Validate mobile number
    const mobileValidationResult = validateMobile(employeeDetails.mobile);

    if (mobileValidationResult) {
      setIsUpdateClicked(false);
      console.error('Invalid mobile number:', mobileValidationResult);
      return;
    }

    console.log('Updating employee details:', employeeDetails);
    const updatedAmount = parseFloat(employeeDetails.Amount || 0);
    const updatedAmountDispensed = parseFloat(employeeDetails.amountDispensed || 0);
    const updatedAmountPaid = parseFloat(employeeDetails.amountPaid || 0);     
    const additionalAmount = parseFloat(operationAmount) + parseFloat(employeeDetails.manageAmount || 0);
    const newAmount = parseFloat(employeeDetails.Amount || 0) + additionalAmount;

    const pinRegex = /^\d{6}$/; 
      if (!pinRegex.test(employeeDetails.pin)) {
        // Notify the user about the invalid input
        setIsUpdateClicked(false);
        // alert('PIN must be up to 6 numbers and should not contain any special characters.');
        return; // Stop further processing if the input is invalid
      }
      setIsUpdateClicked(true);
      employeeDetails.pin = employeeDetails.pin.slice(0, 6);
      employeeDetails.amount = newAmount;
      try {
        
        const requestData = {
          EmployeeId: parseInt(employeeDetails.employeeId, 10) || 0,
          FirstName: String(employeeDetails.firstName),
          LastName: String(employeeDetails.lastName),
          EmailId: String(employeeDetails.emailId),
          Mobile: String(employeeDetails.mobile),
          Pin: String(employeeDetails.pin).slice(0, 6),
          Amount: parseFloat(employeeDetails.amount) || 0, 
          UserId: parseInt(employeeDetails.UserId, 10) || 0,
          Token: String(employeeDetails.Token)
        };
        console.log('Request Data:', requestData);
        // const username = sessionStorage.getItem('username');
        const username = localStorage.getItem('username');

        const updateEmployeeResponse = await fetch(`${getEndpoint('updateEmployeeApiEndpoint')}`,
        {
          method: 'PUT',
          headers: {
            'username': username,
            'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
            // 'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com/subcontractor-list',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });
      
        const responseData = await updateEmployeeResponse.json();
        console.log('Response Data:', responseData);

        if (updateEmployeeResponse.ok) {
          setIsUpdateClicked(true);
          console.log('Data updated successfully:', responseData);
          // Rest of your code...
        }
        else {
          setIsUpdateClicked(false);
          console.error('Failed to update data:', updateEmployeeResponse.status, updateEmployeeResponse.statusText);
        }
      } 
      catch (error) {
        setIsUpdateClicked(false);
        console.error('Network error or other issue:', error);
      }
      setIsUpdateClicked(true);
      toast.success('Employee updated successfully!');
      // navigate('/subcontractor-list', { replace: true });
      // window.location.reload();
      setTimeout(() => {
        navigate('/subcontractor-list', { replace: true });
        window.location.reload();
      }, 4000); 
  };
  
  const handleDelete = async (employeeId) => {
    try {
      const requestData = {
        EmployeeId: employeeId,
      };
  
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://qa.boltkioskadmin.com',
      };
  
      const response = await axios.delete(`${getEndpoint('deleteEmployeeApiEndpoint')}`, {
        data: requestData,
        headers: headers
      });
  
      if (!response.data.success) {
        console.error('Failed to delete data:', response.status, response.statusText, response.data.message);
      } else {
        console.log('Data deleted successfully:', response.data.data);
        // You may want to trigger additional actions after a successful delete
      }
    } catch (error) {
      console.error('Network error or other issue:', error);
    }
    
    toast.success("Employee Deleted successfully!");
    setTimeout(() => {
      setShowDeletePopup(false);
      navigate('/subcontractor-list', { replace: true });
      window.location.reload();
    }, 3000); 
  };
  
  
  return (
    <form onSubmit={handleSubmit} >
      {successMessage && <div className="success-message">{successMessage}</div>}
      {isModifyMode ? (
        <h2>Update / Delete Employee</h2>
      ) : (
        <h2>Add Employee</h2>
      )}

      <div className='pop-up-form'>

        <div className="form-row-top">
        </div>

        <div className="form-row">
          {isModifyMode ? (
            <h2> </h2>
          ) : (
            <h2> </h2>
          )}
          
        </div>
      
        <div className='form-row'>
          <label>
              Employee ID:
              {isModifyMode ? <input
                type="number"
                name="employeeId"
                style={{color: '#fff'}}
                required
                pattern="[1-9]*"
                value={employeeDetails.employeeId || ''}
                disabled={isModifyMode}
                onPaste={handlePaste}
                onDrop={handleDrop} 
                onKeyDown={handleKeyDown} 
                onChange={handleChange}
              /> : 
              <input
                type="number"
                name="employeeId"
                required
                value={employeeDetails.employeeId || ''}
                disabled={isModifyMode}
                onChange={handleChange}
                onPaste={handlePaste}
                onDrop={handleDrop} 
                onKeyDown={handleKeyDown} 
            />}
              
            {isModifyMode ? (
            <h2> </h2>
              ) : (
                <h2>{employeeDetails.employeeId && !/^[0-9]{4}$/.test(employeeDetails.employeeId) && (
                  <div className="error-message">Employee ID must be exactly 4 numbers.</div>
                )}</h2>
            )}
              
          </label> 
        </div>
        
        <div className="form-row">
          <label>
            First Name:
            <input
              type="text"
              required
              name="firstName"
              value={employeeDetails.firstName}
              onChange={handleChange}
              onPaste={handlePaste}
              onDrop={handleDrop} 
              onKeyDown={handleKeyDown} 
              pattern="[A-Za-z]+"
              title="First Name must contain only alphabets."
            />
            {employeeDetails.firstName && !/^[A-Za-z]+$/.test(employeeDetails.firstName) && (
              <div className="error-message">First Name must contain only alphabets.</div>
            )}
          </label>
        </div>

        <div className="form-row">
          <label>
            Last Name:
            <input
              type="text"
              required
              name="lastName"
              value={employeeDetails.lastName}
              onChange={handleChange}
              onPaste={handlePaste}
              onDrop={handleDrop} 
              onKeyDown={handleKeyDown} 
              pattern="[A-Za-z]+"
              title="Last Name must contain only alphabets."
            />
            {employeeDetails.lastName && !/^[A-Za-z]+$/.test(employeeDetails.lastName) && (
              <div className="error-message">Last Name must contain only alphabets.</div>
            )}
          </label>
        </div>

        <div className="form-row">
          <label>
            Email:
            <input type="email" name="emailId" value={employeeDetails.emailId || ''} onChange={handleChange} 
              onPaste={handlePaste}
              onDrop={handleDrop} 
              onKeyDown={handleKeyDown} 
            />
            {emailError && <div className="error-message">{emailError}</div>}
          </label>
        </div>

        <div className="form-row">
          <label>
            Mobile:
            <input type="number" name="mobile" value={employeeDetails.mobile || ''} onChange={handleChange} 
              onPaste={handlePaste}
              onDrop={handleDrop} 
              onKeyDown={handleKeyDown} 
            />
            {mobileError && <div className="error-message">{mobileError}</div>}
            {employeeDetails.mobile && !/^[0-9]+$/.test(employeeDetails.mobile) && (
              <div className="error-message">Mobile number must contain only digits....</div>
            )}
          </label>
        </div>

        <div className="form-row">
          <label>
            Pin:
            {isModifyMode ? (
              <input
                type="number"
                name="pin"
                required
                value={employeeDetails.pin || ''}
                onChange={handleChange}
                onBlur={(e) => handlePinBlur(e.target.value)}
                onPaste={handlePaste}
                onDrop={handleDrop} 
                onKeyDown={handleKeyDown} 
              />
            ) : (
              <input
                type="number"
                name="pin"
                required
                value={employeeDetails.pin || ''}
                onChange={(e) => handlePinChange(e.target.value)}
                onBlur={(e) => handlePinBlur(e.target.value)}
                onPaste={handlePaste}
                onDrop={handleDrop} 
                onKeyDown={handleKeyDown} 
              />
            )}
            {pinError && <div className="error-message">{pinError}</div>}
          </label>
        </div>

        <div className="form-row">
          <label>
            Authorized Amount:
            
            {isModifyMode? 
            <input
              type="number"
              name="amount"
              required
              style={{color: '#fff'}}
              value={employeeDetails.amount || 0}
              disabled={isModifyMode}
              onChange={handleChange}
              onPaste={handlePaste}
              onDrop={handleDrop} 
              onKeyDown={handleKeyDown} 
            />
            : 
            <input
              type="number"
              name="amount"
              required
              value={employeeDetails.amount || ''}
              onChange={handleChange}
              onPaste={handlePaste}
              onDrop={handleDrop} 
              onKeyDown={handleKeyDown} 
            />}
            {authorizedAmountError && <div className="error-message">{authorizedAmountError}</div>}
          </label>
        </div>

        {!isModifyMode && (
          <>
            <div className="form-row">
            </div>
          </>
        )}

        {isModifyMode && (
          <div className="form-row">
            <label>
              Add/Deduct Amount:
              <input
                type="number"
                name="manageAmount"
                operation = 'add'
                value={operationAmount}
                onChange={handleOperationAmountChange}
                onPaste={handlePaste}
                onDrop={handleDrop} 
                onKeyDown={handleKeyDown} 
              />
              {deductAmountError && <div className="error-message">{deductAmountError}</div>}
              <div>
            </div>
            </label>
          </div>
        )}

        {isModifyMode && (
          <div className="form-row">
            <button
              type="submit"
              onClick={handleUpdate}
              className={`submit-btn ${isChangesMade ? '' : 'disabled'}`}
              disabled={!isChangesMade || deductAmountError}
              onPaste={handlePaste}
              onDrop={handleDrop} 
              onKeyDown={handleKeyDown} 
              >
                Update Employee
            </button>
          </div>
        )} 

        {isModifyMode && (
          <div className="form-row">
            <button type="button" onClick={handleDeleteClick} className='submit-btn delete-emp'>
              Delete Employee
            </button>
            {showDeletePopup && (
              <DeleteConfirmationPopup handleDelete={handleDelete} employeeId={employeeDetails.employeeId} handleCancel={handleCancel} />
            )}
          </div>
        )}

        <div className="form-row btn-align">
          {isModifyMode ? '' : 
            <button type="submit" className='form-submit-btn' onSubmit={isModifyMode ? handleUpdate : handleSubmit}>
              {isModifyMode ? 'Update' : 'Add Employee'}
            </button> 
          }
        </div>
      </div>
    </form>
  );
};

export default EmployeeForm;